<template>
  <div>
    <section class="wrapper">
      <!-- <div class="container pt-10 pb-19 pt-md-14 pb-md-20 text-center"> -->
      <div class="container pt-10 text-center">
        <div class="row">
          <div class="col-md-7 col-lg-6 col-xl-5 mx-auto">
            <h1 class="display-1 mb-3">Berita</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light">
      <div class="container py-11">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="blog grid grid-view">
              <div class="row isotope gx-md-8 gy-8 mb-8">
                <article class="item post col-md-6"  v-for="data in list_data" :key="data.id">
                  <div class="card">
                    <figure class="card-img-top overlay overlay-1 hover-scale">
                        <a @click="push_berita(data.id)" href="javascript:void(0)">
                            <img :src="file_path+data.foto" alt="" style="width: 100%; height: 350px !important;"/>
                        </a>
                      <figcaption>
                        <h5 class="from-top mb-0">Selengkapnya</h5>
                      </figcaption>
                    </figure>
                    <div class="card-body">
                      <div class="post-header">
                        <div class="post-category text-line">
                          <a href="#" class="hover" rel="category">{{data.kategori_id}}</a>
                        </div>
                        <!-- /.post-category -->
                        <h2 class="post-title h3 mt-1 mb-3">
                            <a @click="push_berita(data.id)" class="link-dark" href="javascript:void(0)">
                                {{ data.judul }}
                            </a></h2>
                      </div>
                      <!-- /.post-header -->
                      <div class="post-content">
                        <p>{{ data.deskripsi }}</p>
                      </div>
                      <!-- /.post-content -->
                    </div>
                    <!--/.card-body -->
                    <div class="card-footer">
                      <ul class="post-meta d-flex mb-0">
                        <li class="post-date"><i class="uil uil-calendar-alt"></i><span>{{ convert_tgl(data.createAt) }}</span></li>
                      </ul>
                      <!-- /.post-meta -->
                    </div>
                    <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
                </article>
              </div>
              <!-- /.row -->
            </div>
            <!-- /.blog -->
            <div class="text-center">
                    <button @click="btn_prev" type="button" class="btn btn-primary btn-sm rounded-pill" id="pref">
                      <i class="uil uil-arrow-left"></i>
                    </button>
                    &nbsp;
                    <span style="font-size: 85%; color: #a5a5a5">
                        <b>{{page_first}} - {{page_last}}</b>
                    </span>
                    &nbsp;
                    <button @click="btn_next" type="button" class="btn btn-primary btn-sm rounded-pill" id="next">
                      <i class="uil uil-arrow-right"></i>
                    </button>
                </div>
          </div>
          <!-- /column -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </div>
</template>

<script>
const ADD_URL = URL_APP + "api/v1/web_publish_berita/";
const FILE_LOAD = URL_APP + "uploads/";

	export default {

		data() {
			return {
                data_load : false,
                data_load : false,
                form : {
                    id : '',
                    judul : '',
                    sumber : '',
                    deskripsi : '',
                    isi : '',
                    foto : '',
                    unit_kerja : '',
                    status : '',
                    keterangan : '',
                    createBy : '',
                    createAt : '',
                },
                id :'',
                cek_load_data : true,
                file_old: "",
                file_path: FILE_LOAD,

                unit_kerja : UNIT_KERJA,

                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
			}
		},
		methods: {
			getView: function() {
                this.cek_load_data = true;
                fetch(ADD_URL + "viewOPD", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        data_ke: this.page_first,
                        cari_value: this.cari_value,
                        unit_kerja : this.unit_kerja
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_data = res_data.data;
                        this.page_last = res_data.jml_data;
                        this.cek_load_data = false;
                        // console.log(res_data);
                });
            },
            push_berita : function(id){
                this.$router.push('/isiBerita?id='+id);
            },


            // ================== PAGINASI ====================
                alertku: function(type, title) {
                this.$swal({
                    type: type,
                    title: title,
                    showConfirmButton: false,
                    timer: 800
                });
                },

                btn_prev : function(){
                        this.cek_load_data = true;
                    if(this.page_first>1){
                        this.page_first--
                    }else{
                        this.page_first = 1;
                    }
                    this.getView();
                },

                btn_next : function(){
                    
                    if(this.page_first >= this.page_last){
                        this.page_first == this.page_last
                    }else{
                        this.page_first++;
                    }
                    this.getView();
                },

                cari_data : function(){

                    this.page_first = 1;
                    this.getView();
                },
                onFileSelected: function(event) {
                this.form.file = event.target.files[0];
                },
                convert_tgl: function(dateString) {
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Jan'} 
                    else if(getBulan == '2') {bulan = 'Feb'}
                    else if(getBulan == '3') {bulan = 'Mar'}
                    else if(getBulan == '4') {bulan = 'Apr'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Jun'}
                    else if(getBulan == '7') {bulan = 'Jul'}
                    else if(getBulan == '8') {bulan = 'Agt'}
                    else if(getBulan == '9') {bulan = 'Sep'}
                    else if(getBulan == '10') {bulan = 'Okt'}
                    else if(getBulan == '11') {bulan = 'Nov'}
                    else if(getBulan == '12') {bulan = 'Des'}



                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
                }
            // ================== PAGINASI ====================













		},
		mounted() {
            this.getView();

		},

	}
</script>